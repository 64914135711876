import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Contact`}</h1>
    <p>{`These are the places where you can find me.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/alfonsocv12"
        }}>{`GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mailto:alfonsocvu@gmail.com"
        }}>{`Email`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/alfonso102"
        }}>{`Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/alfonso-villalobos-462103186/"
        }}>{`Linkedin`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      